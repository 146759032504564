<template>
	<div>
		<van-nav-bar
			title="預約信息"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="ask">
			<div class="top"></div>
			<div :class="isTrue ? 'form_header opacity_box' : 'form_header'">
				<div class="">
					<van-image class="avatar" :src="require('@/assets/images/vaccines/touxiang.png')"></van-image>
				</div>
				<div class="form_header_title">請填寫預約信息？</div>
			</div>
			<div :class="isTrue ? 'form_wrap opacity_box' : 'form_wrap'">
				<div class="form_item">
					<div class="form_item_label">姓名：</div>
					<van-field type="text" class="form_item_input" v-model="name" placeholder="請填寫" />
				</div>
				<div class="form_item_code">
					<div class="form_item_label">港澳通行證：</div>
					<van-field type="text" class="form_item_input" v-model="id_card" placeholder="請填寫" />
				</div>
				<div class="form_item_code">
					<div class="form_item_label">手機號碼：</div>
					<van-field type="number" class="form_item_input" v-model="mobile" placeholder="請填寫" />
				</div>
				<div class="form_item_code">
					<div class="form_item_label">驗證碼：</div>
					<van-field type="number" class="form_item_input send_input" placeholder="請填寫" />
					<div class="action">
						<div class="action_btn" @click="handleCaptchaP" v-if="!isSend">獲取驗證碼</div>
						<div class="action_btn" v-else >{{last_time}}s后重新获取</div>
					</div>
				</div>
				<div class="form_item_code">
					<div class="form_item_label">接種地點:</div>
					<van-field type="text" v-model="address" class="form_item_input" placeholder="請選擇" disabled @click="handleAddrShow" />
					<div class="up_icon_box">
						<van-image class="up_icon" :src="require('@/assets/images/vaccines/up_icon.png')"></van-image>
						<van-image class="up_icon" :src="require('@/assets/images/vaccines/down.png')"></van-image>
					</div>
					
				</div>
				<div class="form_item_code">
					<div class="form_item_label">接種時間：</div>
					<div class="container" @click="handleCalender">
						<van-field type="text" v-model="times" class="form_item_input" placeholder="請選擇" disabled /> 
					</div>
					<div class="date_icon_box">
						<van-image class="date_icon" :src="require('@/assets/images/vaccines/date_icon.png')"></van-image>
					</div>
				</div>
			</div>
			<div :class="isTrue ? 'foot opacity_box' : 'foot'">
				<van-button class="btn" @click="handleSub">立即預約</van-button>
			</div>
			
			<div class="popup_suc" v-if="captchaShow">
				<div class="captcha_popup_wrap">
					<div class="captcha_box">
						<van-field type="text" v-model="captcha" class="captcha_input" placeholder="請輸入圖形碼" />
						<van-image class="captcha_img" :src="captchaImg"></van-image>
						<div class="captcha_upadte" @click="handleCaptchaP" >換一張</div>
					</div>
					<van-button class="captcha_popup_btn" @click="handleCode">確定</van-button>
					<div class="popup_close" @click="handleClose">
						<van-image class="close_icon" :src="require('@/assets/images/vaccines/close_icon.png')"></van-image>
					</div>
				</div>
			</div>
			
			<div class="popup_address" v-if="isAddrShow">
				<div class="popup_address_wrap">
					<div class="popup_address_header"><div>疫苗接種中心</div> <van-image class="close_img" :src="require('@/assets/images/vaccines/close_img.png')" @click="handleClose"></van-image></div>
					<div class="popup_address_list">
						<van-radio-group v-model="addressId" >
							<div :class="addressId == item.id ? 'address_item' : 'address_item'" v-for="(item, index) in addressList" :key="index" @click="handleSelAddress(item)">
								<div class="">
									<div class="address_item_title">{{item.name}}</div>
									<div class="address_item_text">{{item.address}}</div>
								</div> 
								<van-radio class="radio_item" icon-size="24px" :name="item.id" ></van-radio>
							</div>
						</van-radio-group>
					</div>
				</div>
			</div>
			
			<div class="popup_date" v-if="isOpen">
				<div class="popup_date_wrap">
					<van-datetime-picker
						type="date"
						:min-date="minDate"
						:value='times'
						@cancel="isOpen = false"
						@confirm="selBirth"
					/>
				</div>
			</div>
			
			<div class="popup_suc" v-if="isSucShow">
				<div class="popup_wrap">
					<div class="success_icon_box">
						<van-image class="success_icon" :src="require('@/assets/images/vaccines/success_icon.png')"></van-image>
					</div>
					<div class="success_title">您的預約已成功提交！</div>
					<div class="success_tip">請您注意查看手機短信通知，及時確認預約狀態</div>
				
					<van-button class="popup_sub_btn" @click="handleToList">查看預約信息</van-button>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog, Field, DatetimePicker, RadioGroup, Radio  } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Field)
	   .use(DatetimePicker)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesAskvac',
		data (){
			return {
				isTrue: false,
				isSend: false,
				captchaShow: false,
				isOpen: false,
				isAddrShow: false,
				isSucShow: false,
				name: '',
				id_card: '',
				mobile: '',
				times: '',
				last_time: '',
				address: '',
				captchaImg: '',
				captcha: '',
				addressList: [
					{
					  "id": 1,
					  "name": "港島區-智䝨綜合醫療中心-康健聯營診所",
					  "address": "炮台山電氣道160號木蘭苑地下D2鋪",
					},
					{
					  "id": 2,
					  "name": "港島區-中卓醫務綜合專科醫療中心",
					  "address": "中環畢打街1-3號中建大廈9樓",
					},
					{
					  "id": 3,
					  "name": "九龍區-香港體檢-佐敦旗艦店",
					  "address": "佐敦彌敦道241-243號香港體檢中心7樓",
					},
					{
					  "id": 4,
					  "name": "九龍區-瑞康醫療中心",
					  "address": "尖沙咀美麗華商場A座8樓810-812室",
					},
					{
					  "id": 5,
					  "name": "九龍區-韋予力醫生醫務所（尖沙咀）",
					  "address": "尖沙咀漢口道4號騏生商業中心11樓",
					},
					{
					  "id": 6,
					  "name": "新界區-康健-基健醫務中心",
					  "address": "上水龍豐花園商場29A地鋪",
					},
					{
					  "id": 7,
					  "name": "新界區-康健-基健綜合醫務中心",
					  "address": "元朗青山公路（大馬路）206-216號華昌大廈A座地下C鋪",
					},
					{
					  "id": 8,
					  "name": "新界區-康健醫務中心",
					  "address": "東涌映灣薈商場地下25號鋪",
					}
				],
				addressId: ''
			}
		},
		computed: {
			minDate (){
				let now = new Date().getTime();
				let temp1 = 3600 * 24 * 7 * 1000
				let temp2 = now + temp1
				return new Date(temp2)
			},
			// maxDate (){
			// 	return this.limitDate(20);
			// }
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			
			setTimeout(() => {
				this.isTrue = true
			}, 100);
			
			
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			limitDate (year){//参数：距今多少年
				let now = new Date();
				return new Date((now.getFullYear()-year)+'/'+(now.getMonth()+1)+'/'+now.getDate());
			},
			selBirth (value){
				this.times = this.dateString(value);
				this.isOpen = false;
			},
			dateString (date){
				var year = date.getFullYear(),
					m = date.getMonth() + 1,
					d = date.getDate();
				return year + '/' + m + '/' + d;
			},
			handleAddrShow() {
				this.isAddrShow = true;
			},
			handleSub() {
				
			},
			handleCalender() {
				this.isOpen = true;
			},
			handleCaptchaP() {
				
			},
			handleCode() {
				
			},
			handleCalendar() {
				
			},
			handleClose() {
				this.captchaShow = false;
				this.isAddrShow = false;
			},
			handleSelAddress(item) {
				this.addressId = item.id
				this.address = item.name
				this.isAddrShow = false;
			},
			handleToList() {
				
			},
		}
	}
</script>
<style scoped>
	.ask >>> .van-radio__icon .van-icon {
		border: 1px solid #ccc;
	}
	.ask >>> .van-field__control:disabled {
		color: #323233;
		-webkit-text-fill-color: unset;
	}
	::v-deep input.van-field__control::-webkit-input-placeholder {
	  color: #969799 !important;
	}
	::v-deep input.van-field__control:disabled::-webkit-input-placeholder {
		color: #969799 !important;
	}
</style>
<style lang="scss" scoped>
	.ask{
		width: 100%;
		min-height: 100vh;
		padding: 1.52rem 0.30rem;
		background-color: #F5F6FA;
	}
	.top {
	    position: fixed;
	    top: 0.89rem;
	    left: 0;
	    width: 100%;
	    height: 0.06rem;
	    background-color: #D9D9D9;
	    z-index: 99;
	}
	.top::before {
	    position: absolute;
	    left: 0;
	    top: 0;
	    content: '';
	    width: 100%;
	    height: 0.06rem;
	    background-color: #6681FA;
	}
	
	
	.form_header {
	    width: 100%;
	    display: flex;
	    align-items: center;
	}
	.avatar {
	    width: 0.96rem;
	    height: 0.96rem;
	}
	.form_header_title {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.40rem;
	    padding: 0.32rem;
	    background-color: #fff;
	    border-radius: 0 0.16rem 0.16rem 0.16rem;
	    margin-left: 0.16rem;
	}
	.form_wrap {
	    width: 100%;
	    padding: 0.30rem;
	    margin-top: 0.60rem;
	    border-radius: 0.16rem;
	    background-color: #fff;
	}
	.form_item {
	
	}
	.form_item_code {
	    position: relative;
	    margin-top: 0.32rem;
	    margin-bottom: 0.08rem;
	}
	.form_item_label {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.48rem;
		text-align: left;
		margin-bottom: 0.1rem;
	}
	.form_item_input {
	    width: 100%;
	    height: 0.74rem;
	    padding: 0 0.16rem;
	    border-radius: 0.08rem;
	    border: 0.01rem solid #D7DBE1;
		display: flex;
		align-items: center;
	}
	.tips {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FA5151;
	    line-height: 0.30rem;
	}
	
	.foot {
	    width: 100%;
	    margin-top: 0.92rem;
	}
	.btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.16rem;
	}
	
	
	.ask .form_header,
	.ask .form_wrap,
	.ask .foot{
		opacity: 0;
		transform: translateY(100%);
		transition-property: transform,opacity;
		transition-duration: .4s;
	}
	.opacity_box {
	    opacity: 1 !important;
	}
	.ask .form_header,
	.ask .form_wrap,
	.ask .foot{
		transform: translateY(0);
		/* opacity: 1; */
	}
	.ask .form_header{
		transition-delay: .4s;
	}
	.ask .form_wrap{
		transition-delay: .6s;
	}
	
	.ask .foot{
		transition-delay: .8s;
	}
	
	
	.date_icon_box {
	    position: absolute;
	    right: 0.16rem;
	    top: 0.7rem;
	    width: 0.36rem;
	    height: 0.36rem; 
	}
	.date_icon {
	    width: 0.36rem;
	    height: 0.36rem;
	}
	.up_icon_box {
	    position: absolute;
	    right: 0.16rem;
	    top: 0.8rem;
	    width: 0.23rem;
	    height: 0.16rem;
	}
	.up_icon {
	    width: 0.23rem;
	    height: 0.16rem;
	    display: block;
	}
	
	
	
	
	.popup {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.popup_wrap {
	    width: 6.16rem;
	    padding: 0 0.74rem 0.54rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    position: relative;
	}
	.popup_box {
	    text-align: center;
	}
	.popup_tips {
	    width: 2.20rem;
	    height: 2.20rem;
	    margin-top: -0.72rem;
	}
	.popup_text {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #777F8F;
	    line-height: 0.44rem;
	    margin-top: 0.86rem;
	}
	.popup_btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin-top: 0.80rem;
	}
	
	.popup_close {
	    position: absolute;
	    bottom: -1.36rem;
	    left: 44%;
	    width: 0.72rem;
	    height: 0.72rem;
	}
	.close_icon {
	    width: 0.72rem;
	    height: 0.72rem;
	}
	
	.action {
	    height: 0.74rem;
	    display: flex;
	    align-items: center;
	    position: absolute;
	    top: 0.58rem;
	    right: 0.16rem;
	    z-index: 10;
	}
	
	.action_btn {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #6E87FC;
	    line-height: 0.40rem;
	}
	.send_input {
	    padding-right: 1.60rem;
	}
	
	.popup_suc {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.success_icon_box {
	    text-align: center;
	    margin-top: 0.66rem;
	}
	.success_icon {
	    width: 0.88rem;
	    height: 0.88rem;
	}
	.success_title {
	    font-size: 0.34rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.44rem;
	    text-align: center;
	    margin-top: 0.48rem;
	}
	.success_tip {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.44rem;
	    margin-top: 0.48rem;
	}
	
	.popup_sub_btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin-top: 0.30rem;
	}
	
	.popup_date {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: flex-end;
	    justify-content: center;
	}
	
	.popup_date_wrap {
	    width: 100%;
	    padding: 0.20rem 0.30rem 0.02rem;
	    background-color: #fff;
	    border-radius: 0.16rem 0.16rem 0 0;
	    position: relative;
	}
	
	.popup_address {
	    width: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    display: flex;
	    align-items: flex-end;
	    justify-content: center;
	}
	.popup_address_wrap {
	    width: 100%;
	    height: 10.00rem;
	    padding: 0.30rem;
	    background-color: #fff;
	    border-radius: 0.16rem 0.16rem 0 0;
	    position: relative;
	}
	.popup_address_header {
	    width: 100%;
	    height: 0.80rem;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    font-size: 0.36rem;
	    font-family: PingFang SC-Bold, PingFang SC;
	    font-weight: bold;
	    color: #32363E;
	    line-height: 0.42rem;
	    margin-bottom: 0.30rem;
	}
	.close_img {
	    width: 0.58rem;
	    height: 0.58rem;
	}
	.popup_address_list {
	    width: 100%;
	    height: 8.70rem;
	    overflow: scroll;
	}
	.address_item {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    /* padding: 30rem; */
	    /* border: 1rem solid #D7DBE1; */
	    /* border-radius: 16rem; */
	    margin-bottom: 0.64rem;
	}
	
	.address_item_active {
	    border: 0.01rem solid #6681FA;
	}
	.address_item_title {
	    font-size: 0.36rem;
	    font-family: PingFang SC-Bold, PingFang SC;
	    font-weight: bold;
	    color: #000000;
	    line-height: 0.42rem;
		text-align: left;
	}
	
	.address_item_text {
	    font-size: 0.30rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #000000;
	    margin-top: 0.26rem;
		text-align: left;
	}
	.address_item_active .address_item_title, .address_item_active .address_item_text {
	    color: #6681FA;
	}
	
	.captcha_box {
	    width: 100%;
	    display: flex;
	    align-items: flex-end;
	    /* text-align: center; */
	    padding-top: 0.50rem;
	}
	.captcha_img {
	    width: 1.40rem;
	    height: 0.74rem;
	    margin-left: 0.30rem;
	    margin-right: 0.10rem;
	    border-radius: 0.08rem;
	}
	
	.captcha_input {
	    width: 2.60rem;
	    border: 0.01rem solid #D7DBE1;
	    height: 0.74rem;
	    border-radius: 0.08rem;
	    padding: 0 0.16rem;
		display: flex;
		align-items: center;
	}
	
	.captcha_popup_btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.12rem;
	    margin-top: 0.30rem;
	}
	
	.captcha_popup_wrap {
	    width: 6.16rem;
	    padding: 0 0.40rem 0.54rem;
	    background-color: #fff;
	    border-radius: 0.16rem;
	    position: relative;
	}
	
	.captcha_upadte {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #6681FA;
	}
	.radio_item {
		width: 0.8rem
	}
</style>
